import { ShellThemeProvider, ThemeMode, ThemeModes } from "@sede-x/shell-ds-react-framework";
import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from "react";

type ThemeContextProps = {
  theme: ThemeMode;
  toggleTheme?: () => void;
};

const ThemeContext = createContext<ThemeContextProps>({ theme: ThemeModes.dark });

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<ThemeMode>(
    (localStorage.getItem("MACK_USER_THEME") as ThemeMode) ?? ThemeModes.dark
  );

  const toggleTheme = useCallback(
    () =>
      setTheme(currTheme =>
        currTheme === ThemeModes.light ? ThemeModes.dark : ThemeModes.light
      ),
    []
  );

  useLayoutEffect(() => {
    localStorage.setItem("MACK_USER_THEME", theme);
    if (theme === ThemeModes.light) {
      document.documentElement.classList.remove("dark-mode");
      document.documentElement.classList.add("light-mode");
    } else {
      document.documentElement.classList.remove("light-mode");
      document.documentElement.classList.add("dark-mode");
    }
  }, [theme]);

  const themeProviderValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  return (
    <ShellThemeProvider theme={theme ?? ThemeModes.dark}>
      <ThemeContext.Provider value={themeProviderValue}>{children}</ThemeContext.Provider>
    </ShellThemeProvider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeContext, ThemeProvider, useTheme };
