import { useQuery } from "@apollo/client";
import {
  Button,
  Modal,
  Positions,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { Copy } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { loader } from "graphql.macro";
import { JsonView, allExpanded, darkStyles, defaultStyles } from "react-json-view-lite";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { useTheme } from "shared/contexts/ThemeContext";
import { GqlResponse } from "types";
import { TJsonObject } from "./tracker.types";
import { FETCH_POLICY_NO_CACHE } from "./util";

const inboundRefdataRequestPayload = loader("./graphql/inboundRefDataPayload.graphql");

type TInboundRefdataPayloadResponse = GqlResponse<TJsonObject, "inboundRefDataPayload">;

export const RefdataJsonViewer = ({
  requestId,
  open,
  onClose
}: {
  requestId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { theme } = useTheme();
  const { loading, error, data } = useQuery<TInboundRefdataPayloadResponse>(
    inboundRefdataRequestPayload,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: { id: requestId }
    }
  );
  const customStyles = {
    ...(theme === "light" ? defaultStyles : darkStyles),
    container: "movement-degug-info",
    basicChildStyle: "basic-element-style"
  };

  const copyContent = () => {
    if (data?.inboundRefDataPayload) {
      navigator.clipboard.writeText(JSON.stringify(data.inboundRefDataPayload, null, 2));
    }
  };

  return (
    <Modal
      title="Inbound Refdata Update Request"
      width={"800px"}
      height={"600px"}
      mask={true}
      bodyPadding={false}
      open={open}
      onClose={onClose}>
      {loading && <InlineLoadingPanel />}
      {error && <ApolloErrorViewer error={error} />}
      {data && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px",
              backgroundColor: "var(--nav-selected-bg)"
            }}>
            <Button
              onClick={copyContent}
              size={Sizes.Small}
              title="Copy"
              variant={Variants.Transparent}
              iconPosition={Positions.Right}
              sentiment={Sentiments.Positive}
              icon={<Copy />}>
              Copy
            </Button>
          </div>
          <div
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              padding: "4px"
            }}>
            <JsonView
              data={data.inboundRefDataPayload}
              shouldExpandNode={allExpanded}
              style={customStyles}
              clickToExpandNode={true}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
