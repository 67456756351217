import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  ExpanderButton,
  Flexbox,
  Sizes,
  Table,
  TBody,
  TD as TCell,
  Text,
  THead,
  TH as Thead,
  TR as TRow
} from "@sede-x/shell-ds-react-framework";
import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { loader } from "graphql.macro";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import EmptyTable from "shared/components/basetable/EmptyTable";
import GlobalHeader from "shared/components/GlobalHeader";
import { equalsIgnoreCase } from "ticketing/utils";
import { GqlResponse } from "types";
import LoadingPanel from "../../shared/components/LoadingPanel";
import "./subscriptions.css";
export interface INotificationSubscription {
  notifierId: number;
  serviceName: string;
  entityName: string;
  eventType: string;
  description: string;
  subscriptionId: number;
  subscribed: boolean;
  subscribedAt: string;
  version: number;
}
const GET_SUBSCRIPTIONS = loader("../graphql/query-notification-subscriptions.graphql");
const UPDATE_SUBSCRIPTIONS = loader("../graphql/mutation-update-subscriptions.graphql");
type TNotificationSubscriptionResponse = GqlResponse<
  INotificationSubscription[],
  "subscriptions"
>;
type TNotificationServiceGroup = { group: string; expanded: boolean };
type TCheckChangedHandler<TData> = (val: TData) => void;
const PADDING = 16;

const makeSubscribedCell =
  <TData extends RowData>(onCheckedChanged: TCheckChangedHandler<TData>) =>
  (arg: CellContext<TData, unknown>) => {
    return (
      <Flexbox justifyContent="flex-start" gap="48px">
        <span>&nbsp;</span>
        <Checkbox
          checked={arg.getValue() as boolean}
          onChange={() => onCheckedChanged(arg.row.original)}
        />
      </Flexbox>
    );
  };

const DateCell = <TData extends RowData>(arg: CellContext<TData, unknown>) => {
  const dateValue = arg.getValue() ? new Date(arg.getValue() as string) : undefined;
  return <Text>{dayjs(dateValue).format("MM/DD/YYYY HH:mm:ss")}</Text>;
};

const GroupCell = ({
  group,
  checked,
  onToggleExpand,
  onGroupCheckedChanged
}: {
  group: TNotificationServiceGroup;
  checked: boolean;
  onToggleExpand: (group: string) => void;
  onGroupCheckedChanged: (group: string, checked: boolean) => void;
}) => (
  <Flexbox>
    <ExpanderButton
      expanded={group.expanded}
      onClick={e => {
        e.stopPropagation();
        onToggleExpand(group.group);
      }}
    />
    <Checkbox
      label={<strong>{group.group}</strong>}
      checked={checked}
      onChange={e => {
        e.stopPropagation();
        onGroupCheckedChanged(group.group, e.target.checked);
      }}
    />
  </Flexbox>
);

const Subscription = () => {
  const { loading, error } = useQuery<TNotificationSubscriptionResponse>(GET_SUBSCRIPTIONS, {
    onCompleted: data => {
      setSubscriptions(data.subscriptions);
      const uniqueServices = [
        ...new Set(data?.subscriptions?.map(s => s.serviceName.toUpperCase()))
      ].sort((c1, c2) => c1.localeCompare(c2));
      setServiceGroups(uniqueServices.map(name => ({ group: name, expanded: true })));
    }
  });
  const [updateSubscriptions, { loading: updateLoading }] = useMutation(UPDATE_SUBSCRIPTIONS);
  const [subscriptions, setSubscriptions] = useState<INotificationSubscription[]>();
  const [serviceGroups, setServiceGroups] = useState<TNotificationServiceGroup[]>();
  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [tableTop, setTableTop] = useState<number>(0);

  const onToggleExpand = (serviceName: string) => {
    setServiceGroups(ss =>
      ss?.map(s =>
        equalsIgnoreCase(s.group, serviceName) ? { ...s, expanded: !s.expanded } : s
      )
    );
  };
  const onGroupCheckedChanged = (serviceName: string, checked: boolean) => {
    setSubscriptions(ss =>
      ss?.map(s =>
        equalsIgnoreCase(s.serviceName, serviceName) ? { ...s, subscribed: checked } : s
      )
    );
  };

  const onCheckedChanged = (subscription: INotificationSubscription) => {
    setSubscriptions(ss =>
      ss?.map(s =>
        s.notifierId === subscription.notifierId ? { ...s, subscribed: !s.subscribed } : s
      )
    );
  };

  const columns: ColumnDef<INotificationSubscription>[] = useMemo(
    () => [
      {
        header: "",
        accessorKey: "subscribed",
        cell: makeSubscribedCell(onCheckedChanged)
      },
      {
        header: "Entity",
        accessorKey: "entityName"
      },
      {
        header: "Event",
        accessorKey: "eventType"
      },
      {
        header: "Description",
        accessorKey: "description"
      },
      {
        header: "Last Subscribed At",
        accessorKey: "subscribedAt",
        cell: DateCell
      }
    ],
    []
  );

  useEffect(() => {
    const { innerHeight } = window;
    setTableTop(
      innerHeight -
        (globalHeaderRef?.current?.getBoundingClientRect().top ?? 0) -
        (globalHeaderRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [globalHeaderRef]);

  const onSave = () => {
    updateSubscriptions({
      variables: {
        subscriptions: subscriptions?.map(s => ({
          notifierId: s.notifierId,
          subscribe: s.subscribed
        }))
      }
    });
  };

  const isGroupChecked = useCallback(
    (serviceName: string) =>
      !!subscriptions &&
      subscriptions
        ?.filter(s => equalsIgnoreCase(s.serviceName, serviceName))
        .every(s => s.subscribed),
    [subscriptions]
  );

  const table = useReactTable({
    data: subscriptions ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const isLoading = [loading, updateLoading].some(Boolean);
  const hasSubscriptions = (subscriptions?.length ?? 0) > 0;

  return (
    <>
      <div ref={globalHeaderRef}>
        <GlobalHeader
          pageName="Manage Notifications"
          buttonContent={[
            <Button onClick={onSave} size={Sizes.Small} key={1}>
              Save
            </Button>
          ]}
        />
      </div>

      {error && <ApolloErrorViewer error={error} />}

      {isLoading && <LoadingPanel />}
      {!hasSubscriptions && <EmptyTable />}

      {hasSubscriptions && (
        <div style={{ maxHeight: `${tableTop}px`, overflowY: "auto" }}>
          <Table className="carboniq-data-table">
            <THead>
              {table.getHeaderGroups().map(headerGroup => (
                <TRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <Thead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </Thead>
                  ))}
                </TRow>
              ))}
            </THead>
            <TBody>
              {serviceGroups?.map(serviceGroup => (
                <Fragment key={`group-${serviceGroup.group}`}>
                  <TRow onClick={() => onToggleExpand(serviceGroup.group)}>
                    <TCell colSpan={columns.length}>
                      <GroupCell
                        group={serviceGroup}
                        checked={isGroupChecked(serviceGroup.group)}
                        onToggleExpand={onToggleExpand}
                        onGroupCheckedChanged={onGroupCheckedChanged}
                      />
                    </TCell>
                  </TRow>

                  {serviceGroup.expanded &&
                    table
                      .getRowModel()
                      .rows.filter(
                        sub => sub.original.serviceName.toUpperCase() === serviceGroup.group
                      )
                      .toSorted((a, b) =>
                        a.original.entityName.localeCompare(b.original.entityName)
                      )
                      .map(row => (
                        <TRow key={row.id}>
                          {row.getVisibleCells().map(cell => (
                            <TCell key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TCell>
                          ))}
                        </TRow>
                      ))}
                </Fragment>
              ))}
            </TBody>
          </Table>
        </div>
      )}
    </>
  );
};
export default Subscription;
