import { useQuery } from "@apollo/client";
import { Button, Modal, Positions, Sizes, Variants } from "@sede-x/shell-ds-react-framework";
import { Copy } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { loader } from "graphql.macro";
import XMLViewer from "react-xml-viewer";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse } from "types";
import { FETCH_POLICY_NO_CACHE } from "./util";

const outboundCargoPayload = loader("./graphql/outboundCargoPayload.graphql");

type TCargoMessagePayload = {
  cargoXml: string;
  id: string;
  sentAt: string;
  xmlMessageId: string;
};

type TOutboundCargoPayloadResponse = GqlResponse<TCargoMessagePayload, "outboundCargoPayload">;

export const CargoXmlMessageViewer = ({
  messageId,
  open,
  onClose
}: {
  messageId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { loading, error, data } = useQuery<TOutboundCargoPayloadResponse>(
    outboundCargoPayload,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: { messageId }
    }
  );
  const copyContent = () => {
    if (data?.outboundCargoPayload.cargoXml) {
      navigator.clipboard.writeText(data?.outboundCargoPayload.cargoXml);
    }
  };

  return (
    <Modal
      title="Cargo Message"
      width={"800px"}
      height={"600px"}
      mask={true}
      bodyPadding={false}
      open={open}
      onClose={onClose}>
      {loading && <InlineLoadingPanel />}
      {error && <ApolloErrorViewer error={error} />}
      {data && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2px",
              backgroundColor: "var(--nav-selected-bg)"
            }}>
            <Button
              onClick={copyContent}
              size={Sizes.Small}
              title="Copy"
              variant={Variants.Transparent}
              iconPosition={Positions.Right}
              icon={<Copy />}>
              Copy
            </Button>
          </div>
          <div
            style={{
              backgroundColor: "whitesmoke",
              maxHeight: "600px",
              overflowY: "auto",
              padding: "4px"
            }}>
            <XMLViewer
              xml={data?.outboundCargoPayload.cargoXml}
              collapsible={true}
              initalCollapsedDepth={3}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
