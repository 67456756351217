import BlendComponents from "carbonIQ/BlendComponents/BlendComponents";
import CarbonIQCustomers from "carbonIQ/Customers/CarbonIQCustomers";
import CarbonIQProductBlend from "carbonIQ/ProductBlend/CarbonIQProductBlend";
import CarbonIQProducts from "carbonIQ/Products/CarbonIQProducts";
import CarbonIQSavingsLetter from "carbonIQ/SavingsLetter/CarbonIQSavingsLetter";
import CarbonIQTerminals from "carbonIQ/Terminals/CarbonIQTerminals";
import CarbonIQWeightedAverage from "carbonIQ/WeightedAverages/CarbonIQWeightedAverage";
import Dashboard from "dashboard/Dashboard";
import { useContext } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFoundPage from "shared/components/404";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import AccessRestricted from "shared/components/accessRestricted";

import { PicklistContextLayout } from "ticketing/PicklistContextLayout";
import { InboundCargoTracker } from "ticketing/components/connector-trackers/InboundCargoTracker";
import { InboundContractTracker } from "ticketing/components/connector-trackers/InboundContractTracker";
import { InboundRefdataTracker } from "ticketing/components/connector-trackers/InboundRefdataTracker";
import { OutboundBatchesTracker } from "ticketing/components/connector-trackers/OutboundBatchesTracker";
import { OutboundMovementsTracker } from "ticketing/components/connector-trackers/OutboundMovementsTracker";
import MovementsMainContainer from "ticketing/components/movements/MovementsMainContainer";
import OpsValidation from "ticketing/components/ops-validation/OpsValidation";
import ManageTickets from "ticketing/components/tickets/ManageTickets";
import { MackPrivilege } from "./auth";
import { AuthenticationContext, IAuthenticationContext } from "./auth/AuthenticationProvider";
import LoggingIn from "./auth/LoggingIn";
import LoginError from "./auth/LoginError";
import ForecastImport from "./forecast/ForecastImport";
import Forecast from "./forecast/components/Forecast";
import ForecastExport from "./forecast/components/forecastExport/ForecastExport";
import Notification from "./notification/components/Notification";
import PushNotification from "./notification/components/PushNotification";
import ManageRoles from "./settings/roles/ManageRoles";
import Subscription from "./settings/subscription/Subscription";
import ManageUsers from "./settings/users/ManageUsers";
import DrawerRouterContainer from "./shared/components/DrawerRouterContainer";
import BulkImportTickets from "./ticketing/components/bulkImport/BulkImportTickets";

const App = () => {
  const authContext: IAuthenticationContext = useContext(AuthenticationContext);
  const { mackUser, isLoading, ssoSignInError, mackSignInError } = authContext;

  if (isLoading) {
    return <LoggingIn />;
  }

  if (ssoSignInError) {
    return (
      <LoginError
        error={{
          errorCode: ssoSignInError.cause as string,
          errorDescription: `Login error: ${ssoSignInError.message}`
        }}
      />
    );
  }

  if (mackSignInError) {
    return <ApolloErrorViewer error={mackSignInError} />;
  }

  if (!mackUser?.hasPrivilege(MackPrivilege.Login)) {
    return (
      <LoginError
        error={{
          errorCode: "Access Denied",
          errorDescription: "Not a MACk User, Please contact support"
        }}
      />
    );
  }

  return (
    <div className="App">
      <HashRouter>
        <DrawerRouterContainer>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />

            {mackUser?.hasForcastPrivilege() ? (
              <>
                <Route path="/forecast" element={<Forecast />} />
                <Route path="/forecast/export" element={<ForecastExport />} />
                <Route path="/forecast/import" element={<ForecastImport />} />
              </>
            ) : (
              <Route path="/forecast/*" element={<AccessRestricted />} />
            )}

            {mackUser?.hasMovementsOrTicketingPrivilege() ? (
              <Route element={<PicklistContextLayout />}>
                <Route path="/ticketing" element={<MovementsMainContainer />} />
                <Route path="/ticketing/bulkImport" element={<BulkImportTickets />} />
                <Route path="/ticketing/manageTickets" element={<ManageTickets />} />
                <Route path={"/ticketing/opsvalidations"} element={<OpsValidation />} />
              </Route>
            ) : (
              <Route path="/ticketing/*" element={<AccessRestricted />} />
            )}
            {mackUser?.hasEndurConnectorPrivilege() ? (
              <Route>
                <Route
                  path="/ticketing/trackers/outboundMovements"
                  element={<OutboundMovementsTracker />}
                />
                <Route
                  path="/ticketing/trackers/outboundBatches"
                  element={<OutboundBatchesTracker />}
                />
                <Route
                  path="/ticketing/trackers/inboundCargos"
                  element={<InboundCargoTracker />}
                />
                <Route
                  path="/ticketing/trackers/inboundContracts"
                  element={<InboundContractTracker />}
                />
                <Route
                  path="/ticketing/trackers/inboundRefdata"
                  element={<InboundRefdataTracker />}
                />
              </Route>
            ) : (
              <Route path="/ticketing/trackers/*" element={<AccessRestricted />} />
            )}
            {mackUser?.hasCarbonIQPrivilege() ? (
              <>
                <Route path="/carboniq" element={<CarbonIQSavingsLetter />} />
                <Route path="/carboniq/customers" element={<CarbonIQCustomers />} />
                <Route path="/carboniq/product-blend" element={<CarbonIQProductBlend />} />
                <Route path="/carboniq/products" element={<CarbonIQProducts />} />
                <Route
                  path="/carboniq/weighted-averages"
                  element={<CarbonIQWeightedAverage />}
                />
                <Route path="/carboniq/blend-components" element={<BlendComponents />} />
                <Route path="/carboniq/terminals" element={<CarbonIQTerminals />} />
              </>
            ) : (
              <Route path="/carboniq/*" element={<AccessRestricted />} />
            )}

            <Route path="/notifications" element={<Notification />} />

            <Route
              path="/settings/manage/users"
              element={
                mackUser?.hasPrivilege(MackPrivilege.ViewUsers) ? (
                  <ManageUsers />
                ) : (
                  <AccessRestricted />
                )
              }
            />

            <Route
              path="/settings/manage/roles"
              element={
                mackUser?.hasPrivilege(MackPrivilege.ViewRoles) ? (
                  <ManageRoles />
                ) : (
                  <AccessRestricted />
                )
              }
            />

            <Route path={"/settings/manage/subscriptions"} element={<Subscription />} />

            <Route path={"/not-found"} element={<NotFoundPage />} />

            <Route path={"*"} element={<Navigate to="/not-found" />} />
          </Routes>
          {/* </Suspense> */}
        </DrawerRouterContainer>
      </HashRouter>
      <PushNotification />
    </div>
  );
};

export default App;
