import { IntegerQueryOperatorInput, StringQueryOperatorInput } from "ticketing/ticketing.types";
import { splitByComma, toEqOrInQueryOperator } from "ticketing/utils";
import {
  BatchUpdateQueueStatus,
  BatchUpdateQueueStatusInput,
  CargoUpdateRequestStatus,
  CargoUpdateRequestStatusInput,
  ContractUpdateRequestStatus,
  ContractUpdateRequestStatusInput,
  MovementUpdateQueueStatus,
  MovementUpdateQueueStatusInput,
  RefdataMessageProcessStatus,
  RefdataMessageProcessStatusInput,
  TBatchUpdateQueueFilterInput,
  TCargoUpdateRequestFilterInput,
  TContractUpdateRequestFilterInput,
  TInboundCargoSearchCriteria,
  TInboundContractSearchCriteria,
  TInboundRefdataSearchCriteria,
  TMovementUpdateQueueFilterInput,
  TOutboundBatchSearchCriteria,
  TOutboundMovementSearchCriteria,
  TRefDataMessageFilterInput
} from "./tracker.types";

export const toMovementUpdateQueueFilterInput = (
  filter: TOutboundMovementSearchCriteria
): TMovementUpdateQueueFilterInput => {
  return {
    receivedAt: {
      bw: {
        start: filter.startDate.slice(0, -1),
        end: filter.endDate.slice(0, -1)
      }
    },
    endurDeliveryId: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.endurDeliveryId)
    ),
    endurBatchName: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.endurBatchName)
    ),
    status: toEqOrInQueryOperator<MovementUpdateQueueStatus, MovementUpdateQueueStatusInput>(
      filter.status?.map(
        s => MovementUpdateQueueStatus[s as keyof typeof MovementUpdateQueueStatus]
      )
    )
  };
};

export const toInboundCargoRequestFilterInput = (
  filter: TInboundCargoSearchCriteria
): TCargoUpdateRequestFilterInput => {
  return {
    receivedAt: {
      bw: {
        start: filter.startDate.slice(0, -1),
        end: filter.endDate.slice(0, -1)
      }
    },
    cargoId: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.cargoId)
    ),
    cargoName: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.cargoName)
    ),
    status: toEqOrInQueryOperator<CargoUpdateRequestStatus, CargoUpdateRequestStatusInput>(
      filter.status?.map(
        s => CargoUpdateRequestStatus[s as keyof typeof CargoUpdateRequestStatus]
      )
    ),
    deliveryIds: splitByComma(filter?.deliveryIds)
  };
};
export const toInboundContractRequestFilterInput = (
  filter: TInboundContractSearchCriteria
): TContractUpdateRequestFilterInput => {
  return {
    receivedAt: {
      bw: {
        start: filter.startDate.slice(0, -1),
        end: filter.endDate.slice(0, -1)
      }
    },
    contractId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      splitByComma(filter?.contractId)?.map(Number) ?? 0
    ),
    contractNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.contractNumber)
    ),
    status: toEqOrInQueryOperator<
      ContractUpdateRequestStatus,
      ContractUpdateRequestStatusInput
    >(
      filter.status?.map(
        s => ContractUpdateRequestStatus[s as keyof typeof ContractUpdateRequestStatus]
      )
    )
  };
};

export const toBatcUpdateQueueFilterInput = (
  filter: TOutboundBatchSearchCriteria
): TBatchUpdateQueueFilterInput => {
  return {
    receivedAt: {
      bw: {
        start: filter.startDate.slice(0, -1),
        end: filter.endDate.slice(0, -1)
      }
    },
    name: toEqOrInQueryOperator<string, StringQueryOperatorInput>(splitByComma(filter?.name)),
    status: toEqOrInQueryOperator<BatchUpdateQueueStatus, BatchUpdateQueueStatusInput>(
      filter.status?.map(s => BatchUpdateQueueStatus[s as keyof typeof BatchUpdateQueueStatus])
    )
  };
};

export const toInboundRefdataRequestFilterInput = (
  filter: TInboundRefdataSearchCriteria
): TRefDataMessageFilterInput => {
  return {
    receivedAt: {
      bw: {
        start: filter.startDate.slice(0, -1),
        end: filter.endDate.slice(0, -1)
      }
    },
    entityName: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.entityName)
    ),
    status: toEqOrInQueryOperator<
      RefdataMessageProcessStatus,
      RefdataMessageProcessStatusInput
    >(
      filter.status?.map(
        s => RefdataMessageProcessStatus[s as keyof typeof RefdataMessageProcessStatus]
      )
    )
  };
};

export const FETCH_POLICY_NO_CACHE = "no-cache";
