import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  MultiColumnComboBox,
  MultiColumnComboBoxColumn
} from "@progress/kendo-react-dropdowns";
import {
  TextArea,
  TextAreaChangeEvent,
  TextBox,
  TextBoxChangeEvent
} from "@progress/kendo-react-inputs";
import { Hint, Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useSearchBatches from "ticketing/hooks/useSearchBatches";
import useSearchCarriers from "ticketing/hooks/useSearchCarriers";
import useSearchFacilities from "ticketing/hooks/useSearchFacilities";
import useSearchProducts from "ticketing/hooks/useSearchProducts";
import {
  TMovement,
  TMovementGroup,
  TPdfDocument,
  TShipCode,
  TTicketInput
} from "ticketing/ticketing.types";
import {
  MODE_OF_TRANSPORT_RAIL,
  dealRequiresGrossVolume,
  getCurrentCSTDate,
  isCarrierRequired,
  isMovementDaily,
  isPhysicalDeal,
  isSTCan,
  isStorageDeal,
  isValidPageRange
} from "../../utils";
import { filterShipFromCodes, filterShipToCodes } from "../movements/shipcode-filter";
import "./TicketForm.css";
import TicketVolumesWrapper from "./TicketVolumesWrapper";

const getMaxTicketDate = (movement: TMovement | undefined) => {
  if (movement) {
    const { recDeal, delDeal } = movement;
    if (isStorageDeal(delDeal)) {
      return delDeal?.commitment?.endDate as Date;
    }
    if (isStorageDeal(recDeal)) {
      return recDeal?.commitment?.endDate as Date;
    }
  }
  return undefined;
};
const getMinTicketDate = (movement: TMovement | undefined) => {
  if (movement) {
    const { recDeal, delDeal } = movement;
    if (isStorageDeal(delDeal)) {
      return (delDeal?.commitment?.startDate as Date) ?? undefined;
    }
    if (isStorageDeal(recDeal)) {
      return (recDeal?.commitment?.startDate as Date) ?? undefined;
    }
  }
  return undefined;
};

const shipCodeColumns: MultiColumnComboBoxColumn[] = [
  { field: "code", header: "Ship Code", width: "80px" },
  { field: "description", header: "Description", width: "360px" }
];

const MAX_COMMENT_LENGTH = 35;
const MAX_PO_LENGTH = 25;
const TICKET_NUMBER_VALID_REGEX = /[^\w\s-]/gi;
const TEXT_INPUT_VALID_REGEX = /[^\x20-\x7E]+/g;
const PAGE_NUMBER_VALID_REGEX = /[^0-9\-,]/gi;
const FORM_INPUT_CLASS_NAME = "theme-form-input";

type TicketFormProps = {
  activeTicket: TTicketInput;
  activeMovementGroup?: TMovementGroup | null;
  canSplitTicket: boolean;
  canDeleteTicket: boolean;
  pdfFile: TPdfDocument;
  onTicketChanged: (ticket: TTicketInput, change: { [key: string]: unknown }) => void;
  onCopyTicket: (ticket: TTicketInput) => void;
  onDeleteTicket: (ticket: TTicketInput) => void;
};
const TicketForm = ({
  activeTicket,
  activeMovementGroup,
  canSplitTicket,
  canDeleteTicket,
  pdfFile,
  onTicketChanged,
  onCopyTicket,
  onDeleteTicket
}: TicketFormProps) => {
  const { picklists } = usePicklists();
  const [showErrors, setShowErrors] = useState(false);

  const toggleCollapsed = () => {
    onTicketChanged(activeTicket, { collapsed: !activeTicket.collapsed });
  };

  const hasMovement = !!activeMovementGroup;
  const linkedTicketsCount = activeMovementGroup?.activeMovement?.tickets?.length ?? 0;
  const ticketMaxDate =
    getMaxTicketDate(activeMovementGroup?.activeMovement) ?? getCurrentCSTDate(new Date());
  const ticketMinDate = getMinTicketDate(activeMovementGroup?.activeMovement);
  const ticketDateDisabled =
    hasMovement && linkedTicketsCount > 0 && isMovementDaily(activeMovementGroup);
  const canEditTicketDetails = !hasMovement;

  //#region filters
  const [searchBatches, batchResults, batchesLoading, , clearBatches] = useSearchBatches();
  const onBatchFilter = (e: ComboBoxFilterChangeEvent) => {
    if (e.filter.value.length > 0) {
      searchBatches(e.filter.value);
    }
  };
  const [searchCarriers, carrierResults, carriersLoading, , carriersClear] =
    useSearchCarriers();
  const onCarrierFilter = (e: ComboBoxFilterChangeEvent) => {
    if (e.filter.value.length > 0) {
      searchCarriers(e.filter.value);
    }
  };

  const [searchProducts, productResults, productsLoading, , clearProducts] =
    useSearchProducts();
  const onProductFilter = (e: ComboBoxFilterChangeEvent) => {
    if (e.filter.value.length > 0) {
      searchProducts(e.filter.value);
    }
  };
  const [searchFacilities, facilitiesResults, facilitiesLoading, , clearFacilities] =
    useSearchFacilities();
  const onFacilitiesFilter = (e: ComboBoxFilterChangeEvent) => {
    if (e.filter.value.length > 0) {
      searchFacilities(e.filter.value);
    }
  };

  const [shipFromCodes, setShipFromCodes] = useState<TShipCode[]>(
    filterShipFromCodes(picklists?.shipFrom, activeMovementGroup).map(s => ({
      ...s,
      display: `${s.code}-${s.description}`
    }))
  );

  const onShipFromFilterChange = (event: ComboBoxFilterChangeEvent) => {
    setShipFromCodes(
      filterShipFromCodes(picklists?.shipFrom, activeMovementGroup)
        .filter(
          s =>
            (event.filter.value?.length ?? 0) === 0 ||
            s.code.includes(event.filter.value) ||
            s.description.toLowerCase().includes(event.filter.value.toLowerCase())
        )
        .map(s => ({
          ...s,
          display: `${s.code}-${s.description}`
        }))
    );
  };

  const [shipToCodes, setShipToCodes] = useState<TShipCode[]>(
    filterShipToCodes(picklists?.shipTo, activeMovementGroup).map(s => ({
      ...s,
      display: `${s.code}-${s.description}`
    }))
  );

  const onShipToFilterChange = (event: ComboBoxFilterChangeEvent) => {
    setShipToCodes(
      filterShipToCodes(picklists?.shipTo, activeMovementGroup)
        .filter(
          s =>
            (event.filter.value?.length ?? 0) === 0 ||
            s.code.includes(event.filter.value) ||
            s.description.toLowerCase().includes(event.filter.value.toLowerCase())
        )
        .map(s => ({
          ...s,
          display: `${s.code}-${s.description}`
        }))
    );
  };
  //#endregion

  const isShipFromValid =
    !isPhysicalDeal(activeMovementGroup?.activeMovement?.recDeal) ||
    activeTicket?.shipFromCode != null;
  const isShipToValid =
    !isPhysicalDeal(activeMovementGroup?.activeMovement?.delDeal) ||
    activeTicket?.shipToCode != null;

  const onTextInputChange = (e: TextBoxChangeEvent) => {
    if (e.target.element?.name && e.value != null) {
      switch (e.target.element.name) {
        case "ticketNumber":
        case "extSourceTicketRef":
          e.value = e.value?.toString().replace(TICKET_NUMBER_VALID_REGEX, "").trim();
          break;
        case "railcars":
          e.value = e.value?.toString().replace(TEXT_INPUT_VALID_REGEX, "");
          break;
        case "pageNumbers":
          e.value = e.value?.toString().replace(PAGE_NUMBER_VALID_REGEX, "");
          break;
        default:
          break;
      }
      onTicketChanged(activeTicket, { [e.target.element?.name]: e.value });
    }
  };

  const onInputChange = (
    e: ComboBoxChangeEvent | DatePickerChangeEvent | TextAreaChangeEvent
  ) => {
    if (e.target.name) {
      if (["invoiceComment", "poNumber"].includes(e?.target?.name)) {
        e.value = e.value?.toString().replace(TEXT_INPUT_VALID_REGEX, "");
      }
      if (e?.target?.name === "carrier") {
        onTicketChanged(activeTicket, { carrier: e.value, carrierScacCode: e.value?.scac });
      } else {
        onTicketChanged(activeTicket, { [e.target.name]: e.value });
      }
    }
  };

  const isPageRangeValid =
    pdfFile.docFile == null ||
    (activeTicket.pageNumbers != null &&
      pdfFile.numberOfPages != null &&
      isValidPageRange(activeTicket.pageNumbers, pdfFile.numberOfPages));

  const hasErrors = (activeTicket.errors?.length ?? 0) > 0;

  //#region markup
  return (
    <div className="na-product-wrapper">
      <div
        style={{
          display: "flex",
          gap: "5px",
          padding: "5px",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          backgroundColor: "var(--modal-title-bg)",
          borderBottom: "1px solid var(--modal-title-border)",
          color: "var(--modal-title-txt)"
        }}>
        <div className="field">
          <Label>*Ticket Id</Label>
          <TextBox
            name={"ticketNumber"}
            autoComplete="off"
            autoCorrect="off"
            placeholder="numbers/letters/dash"
            value={activeTicket.ticketNumber ?? ""}
            onChange={onTextInputChange}
            size={"small"}
            valid={(activeTicket.ticketNumber?.length ?? 0) > 0}
            style={{ minWidth: "120px" }}
            className={FORM_INPUT_CLASS_NAME}
          />
        </div>
        <div className="field">
          <Label>*Ticket Date</Label>
          <DatePicker
            name="startDate"
            disabled={ticketDateDisabled}
            onChange={onInputChange}
            value={activeTicket.startDate ? new Date(activeTicket.startDate) : null}
            width={120}
            format={"MM/dd/yyyy"}
            size={"small"}
            max={ticketMaxDate}
            min={ticketMinDate}
            valid={!!activeTicket.startDate}
            className={FORM_INPUT_CLASS_NAME}
          />
        </div>
        <div className="field">
          <Label>TMS BOL #:</Label>
          <TextBox
            name={"extSourceTicketRef"}
            autoComplete="off"
            autoCorrect="off"
            placeholder="numbers/letters/dash"
            value={activeTicket.extSourceTicketRef ?? ""}
            onChange={onTextInputChange}
            size={"small"}
            style={{ minWidth: "96px" }}
            className={FORM_INPUT_CLASS_NAME}
          />
        </div>
        <div className="field">
          <Label>*Pages</Label>
          <TextBox
            name="pageNumbers"
            onChange={onTextInputChange}
            value={activeTicket?.pageNumbers ?? ""}
            disabled={pdfFile.docFile == null}
            style={{ maxWidth: "96px" }}
            size={"small"}
            valid={isPageRangeValid}
            className={FORM_INPUT_CLASS_NAME}
          />
        </div>
        <div className="field">
          {canSplitTicket && (
            <Button
              icon="arrows-resizing k-rotate-90 k-icon-lg"
              fillMode={"flat"}
              themeColor={"info"}
              title="Copy/Split Ticket"
              autoFocus
              onClick={() => onCopyTicket(activeTicket)}
              disabled={!canSplitTicket}></Button>
          )}
          <Button
            icon="delete k-icon-lg"
            fillMode={"flat"}
            title={"Delete Ticket"}
            disabled={!canDeleteTicket}
            style={{ color: "red" }}
            themeColor={"primary"}
            onClick={() => onDeleteTicket(activeTicket)}></Button>
        </div>
      </div>
      {activeTicket && (
        <div>
          <TicketVolumesWrapper
            activeTicket={activeTicket}
            onTicketsChanged={onTicketChanged}
            showGrossVolumeWarning={dealRequiresGrossVolume(
              activeMovementGroup?.activeMovement
            )}></TicketVolumesWrapper>
        </div>
      )}
      {hasErrors && (
        <Icon
          className="k-ml-2.5 k-cursor-pointer"
          themeColor="warning"
          name={showErrors ? "minus-outline" : "plus-outline"}
          onClick={() => setShowErrors(!showErrors)}></Icon>
      )}
      {showErrors && hasErrors && (
        <div className="error-message" style={{ marginLeft: "10px" }}>
          <ul style={{ margin: "0", padding: "0", listStyle: "none" }}>
            {activeTicket.errors
              ?.filter(e => e?.message)
              .toSorted((e1, e2) => Number(e1?.warning ?? false) - Number(e2?.warning ?? false))
              .map(e => (
                <li
                  key={e?.message ?? ""}
                  style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                  <span
                    className="k-icon k-icon-xs k-i-warning-triangle"
                    style={{ color: e?.error ? "red" : "orange" }}></span>
                  <span
                    style={{
                      margin: "2px 4px",
                      color: e?.error ? "red" : "black"
                    }}>
                    {e?.message ?? ""}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}

      <div className="k-m-2.5 ticket-details-wrapper">
        <div className="ticket-details-header" onClick={() => toggleCollapsed()} role="none">
          <Label style={{ fontSize: "1rem", fontWeight: "bold", userSelect: "none" }}>
            Details
          </Label>
          <Button
            icon={`${activeTicket.collapsed ? "arrow-60-down" : "arrow-60-up"} k-icon-lg`}
            fillMode={"flat"}
            title={
              (activeTicket.collapsed ? "Expand" : "Collapse") + " Details section"
            }></Button>
        </div>

        {!activeTicket.collapsed && (
          <div className="ticket-details">
            <Label>
              {isCarrierRequired(activeMovementGroup as TMovementGroup, activeTicket) && "*"}
              Carrier
            </Label>
            <ComboBox
              name={"carrier"}
              data={carrierResults}
              textField="name"
              dataItemKey="id"
              value={activeTicket?.carrier}
              onChange={onInputChange}
              filterable={true}
              onFilterChange={onCarrierFilter}
              loading={carriersLoading}
              onBlur={() => carriersClear()}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span style={{ justifySelf: "start" }}>
              {isSTCan(activeMovementGroup as TMovementGroup) &&
                activeTicket?.carrier == null && <Icon name="warning" themeColor="warning" />}
            </span>
            <Label>Batch Name</Label>
            <ComboBox
              name={"batch"}
              data={batchResults}
              textField="name"
              disabled={!canEditTicketDetails}
              dataItemKey="id"
              value={activeTicket?.batch}
              filterable={true}
              loading={batchesLoading}
              onChange={onInputChange}
              onFilterChange={onBatchFilter}
              onBlur={() => clearBatches()}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span />
            <Label>SCAC Code</Label>
            <TextBox
              name={"carrierScacCode"}
              autoComplete="off"
              autoCorrect="off"
              maxLength={255}
              disabled={!activeTicket.carrier || !!activeTicket.carrier?.scac}
              placeholder="numbers/letters/dash"
              value={activeTicket?.carrierScacCode ?? ""}
              onChange={onTextInputChange}
              size={"small"}
              valid={(activeTicket.ticketNumber?.length ?? 0) > 0}
              style={{ minWidth: "120px" }}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span style={{ justifySelf: "start" }}>
              {!isShipFromValid && <Icon name="warning" themeColor="warning" />}
            </span>
            <Label>Product</Label>
            <ComboBox
              name={"product"}
              data={productResults}
              textField="name"
              dataItemKey="id"
              disabled={!canEditTicketDetails}
              value={activeTicket?.product}
              filterable={true}
              onChange={onInputChange}
              onFilterChange={onProductFilter}
              loading={productsLoading}
              onBlur={() => clearProducts()}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span />
            <Label>Ship From</Label>
            <MultiColumnComboBox
              name={"shipFromCode"}
              columns={shipCodeColumns}
              data={shipFromCodes}
              dataItemKey="id"
              textField={"display"}
              filterable={true}
              value={activeTicket?.shipFromCode}
              onChange={onInputChange}
              onFilterChange={onShipFromFilterChange}
              size={"small"}
              adaptive={true}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span style={{ justifySelf: "start" }}>
              {!isShipFromValid && <Icon name="warning" themeColor="warning" />}
            </span>

            <Label>Ship To</Label>
            <MultiColumnComboBox
              name={"shipToCode"}
              data={shipToCodes}
              dataItemKey="id"
              columns={shipCodeColumns}
              textField={"display"}
              value={activeTicket?.shipToCode}
              filterable={true}
              onChange={onInputChange}
              onFilterChange={onShipToFilterChange}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span style={{ justifySelf: "start" }}>
              {!isShipToValid && <Icon name="warning" themeColor="warning" />}
            </span>
            <Label>Location</Label>
            <ComboBox
              name={"facility"}
              data={facilitiesResults}
              dataItemKey="id"
              textField="name"
              disabled={!canEditTicketDetails}
              filterable={true}
              value={activeTicket?.facility}
              onChange={onInputChange}
              onFilterChange={onFacilitiesFilter}
              loading={facilitiesLoading}
              onBlur={() => clearFacilities()}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span />
            <Label>Border Crossing</Label>
            <DatePicker
              name="borderCrossingDate"
              id="borderCrossingDate"
              onChange={onInputChange}
              format={"MM/dd/yyyy"}
              value={activeTicket.borderCrossingDate}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span />
            <Label>Logistics System</Label>
            <ComboBox
              name={"logisticsSystem"}
              data={picklists?.logisticsSystems}
              dataItemKey="id"
              textField="name"
              disabled={!canEditTicketDetails}
              filterable={true}
              value={activeTicket?.logisticsSystem}
              onChange={onInputChange}
              size={"small"}
              className={FORM_INPUT_CLASS_NAME}
            />
            <span />
            <Label>Invoice Comments</Label>
            <TextArea
              name={"invoiceComment"}
              autoComplete="off"
              rows={1}
              maxLength={MAX_COMMENT_LENGTH}
              onChange={onInputChange}
              value={activeTicket?.invoiceComment ?? ""}
              size={"small"}
              spellCheck={false}
              className={FORM_INPUT_CLASS_NAME}
            />
            <Hint direction={"end"} className="k-white-space-nowrap">
              ({activeTicket?.invoiceComment?.length ?? 0}/{MAX_COMMENT_LENGTH})
            </Hint>

            <Label>PO Number</Label>
            <TextArea
              name={"poNumber"}
              autoComplete="off"
              rows={1}
              maxLength={MAX_PO_LENGTH}
              onChange={onInputChange}
              value={activeTicket?.poNumber ?? ""}
              spellCheck={false}
              className={FORM_INPUT_CLASS_NAME}
            />
            <Hint direction={"start"} className="k-white-space-nowrap">
              ({activeTicket?.poNumber?.length ?? 0}/{MAX_PO_LENGTH})
            </Hint>
            <Label></Label>
            <Label></Label>
            <Label></Label>
            {activeTicket.modeOfTransport?.name === MODE_OF_TRANSPORT_RAIL && (
              <>
                <Label>
                  Railcar<span style={{ color: "red" }}>*</span>
                </Label>
                <TextBox
                  name={"railcars"}
                  autoComplete="off"
                  disabled={false}
                  onChange={onTextInputChange}
                  value={activeTicket?.railcars ?? ""}
                  placeholder="Please enter comma separated values"
                  style={{ gridColumn: "auto / span 4" }}
                  valid={!!activeTicket?.railcars}
                  size={"small"}
                  spellCheck={false}
                  className={FORM_INPUT_CLASS_NAME}
                />
                <span />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default TicketForm;
